import * as BABYLON from 'babylonjs';
import { GridMaterial } from 'babylonjs-materials';

export function CreateGrid(params, scene) {
  const size = params.distance;
  const ratio = params.size2 / params.size1;
  const ground = BABYLON.MeshBuilder.CreateGround(
    'REAL_GRID_MESH',
    { width: size, height: size },
    scene
  );
  const { opacity = 1, color = new BABYLON.Color3(1, 1, 1) } = params;
  let gridMaterial = new GridMaterial('REAL_GRID_MESH_MAT', scene);
  gridMaterial.gridRatio = ratio;
  gridMaterial.lineColor = color;
  // Opacity of lines only
  gridMaterial.opacity = opacity * 5;
  ground.material = gridMaterial;
  ground.material.backFaceCulling = false;
  return ground;
}
