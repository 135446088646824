<template>
  <div class="grid grid-cols-3 mt-2">
    <div class="flex justify-start ml-2 space-x-4">
      <FileMenu
        :icons="icons"
        :scene="scene"
        @exportScene="exportScene"
        @addClickToggle="addClickToggle"
        class="absolute"
      />
    </div>

    <div class="flex justify-center space-x-2">
      <MenuButton
        v-if="enableTransform()"
        title="Local Transform"
        :img="icons.transformIcon"
        :bg="activeColor(sceneToggle.isLocal)"
        :hbg="activeColor(sceneToggle.isLocal, true)"
        @click="localTransformClicked"
      />
      <MenuButton title="Render" :img="icons.renderIcon" @click="renderClick" />
    </div>

    <div class="flex justify-end space-x-2 mr-2">
      <div class="flex space-x-2 mr-12">
        <MenuButton
          title="Material Preview"
          :img="icons.matIcon"
          :bg="activeBG('material')"
          :hbg="activeBG('material', true)"
          @click="
            setActiveButton('material');
            changePreview(false);
          "
        />
        <MenuButton
          title="Light Preview"
          :img="icons.lightIcon"
          :bg="activeBG('light')"
          :hbg="activeBG('light', true)"
          @click="
            setActiveButton('light');
            changePreview(true);
          "
        />
        <MenuButton
          title="Camera view"
          :img="icons.camIcon"
          :bg="activeColor(sceneToggle.camera)"
          :hbg="activeColor(sceneToggle.camera, true)"
          @click="cameraModeClicked"
        />
      </div>

      <div class="flex mr-2">
        <UserProfile
          :icons="icons"
          :cache="cache"
          :isOnline="isOnline"
          :userData="userData"
          @logoutClicked="logoutClicked"
        />
      </div>

      <div
        v-if="enableTransform()"
        class="absolute flex flex-col space-y-2 mt-16 mr-2"
      >
        <MenuButton
          title="Translate"
          v-if="transform.position"
          :img="icons.translateIcon"
          :bg="activeTransformBG('translate')"
          :hbg="activeTransformBG('translate', true)"
          @click="setTransformMode('translate')"
        />
        <MenuButton
          title="Rotate"
          v-if="transform.rotation"
          :img="icons.rotateIcon"
          :bg="activeTransformBG('rotate')"
          :hbg="activeTransformBG('rotate', true)"
          @click="setTransformMode('rotate')"
        />
        <MenuButton
          title="Scale"
          v-if="transform.scaling"
          :img="icons.scaleIcon"
          :bg="activeTransformBG('scaling')"
          :hbg="activeTransformBG('scaling', true)"
          @click="setTransformMode('scaling')"
        />
        <MenuButton
          title="Move camera to view"
          v-if="transform.clone"
          :img="icons.cloneIcon"
          @click="cloneCamera"
        />
        <MenuButton
          title="Modify light"
          v-if="transform.light"
          :img="icons.bulbIcon"
          @click="modifyLight"
        />
      </div>
    </div>

    <div>
      <TransformGui
        v-if="enableTransform()"
        :transObj="transObj"
        :transform="transform"
      />
      <AddCamera
        v-if="addToggle.camera"
        :scene="scene"
        @closeMenus="addToggle.camera = false"
        @addSceneItem="addSceneItem"
      />
      <AddSunLight
        v-if="addToggle.sunLight"
        :scene="scene"
        @closeMenus="addToggle.sunLight = false"
        @addSceneItem="addSceneItem"
      />
      <AddAreaLight
        v-if="addToggle.areaLight"
        :scene="scene"
        @closeMenus="addToggle.areaLight = false"
        @addSceneItem="addSceneItem"
      />
      <AddSpotLight
        v-if="addToggle.spotLight"
        :scene="scene"
        @closeMenus="addToggle.spotLight = false"
        @addSceneItem="addSceneItem"
      />
      <AddPointLight
        v-if="addToggle.pointLight"
        :scene="scene"
        @closeMenus="addToggle.pointLight = false"
        @addSceneItem="addSceneItem"
      />
      <ModifyLight
        v-if="sceneToggle.light"
        :scene="scene"
        @closeMenus="$emit('setSceneToggle', 'light', false)"
      />
      <!--      <ExportPage v-if="sceneToggle.export" :scene="scene" @closeMenus="$emit('setSceneToggle', 'export', false)" />-->
    </div>
  </div>
</template>
<script>
import * as REAL from 'real_api_bbl';
import { IconsUI } from '@/utils/IconsUI';
import EventBus from '@/utils/EventBus';
import AddCamera from '@/pages/AddCamera.vue';
import CacheManager from '@/utils/CacheManager';
import { haveLoggedIn } from '@/tools/login_tools';
import AddSunLight from '@/pages/AddSunLight.vue';
import AddAreaLight from '@/pages/AddAreaLight.vue';
import FileMenu from '@/components/ui/FileMenu.vue';
// import ExportPage from "@/pages/ExportPage.vue";
import AddSpotLight from '@/pages/AddSpotLight.vue';
import Scene from '@/render_utils/three_tools/Scene';
import { RequestManager } from '@/utils/RequestManager';
import MenuButton from '@/components/ui/MenuButton.vue';
import UserProfile from '@/components/ui/UserProfile.vue';
import ModifyLight from '@/pages/ModifyLight.vue';
import AddPointLight from '@/pages/AddPointLight.vue';
import TransformGui from '@/components/render/TransformGui.vue';
import {
  getMetaData,
  isRealLight,
} from '@/render_utils/three_tools/creation_tools/mesh_tools';

export default {
  name: 'SceneGui',
  components: {
    AddPointLight,
    AddSpotLight,
    // ExportPage,
    ModifyLight,
    TransformGui,
    AddAreaLight,
    AddSunLight,
    AddCamera,
    UserProfile,
    FileMenu,
    MenuButton,
  },
  emits: [
    'loginUser',
    'setCamera',
    'logoutUser',
    'renderScene',
    'exportScene',
    'addSceneItem',
    'setLocalMode',
    'setSceneToggle',
    'setTransformMode',
  ],
  props: {
    cred: { type: Object, required: true },
    scene: { type: Scene, required: true },
    transObj: { type: [Object, undefined] },
    icons: { type: IconsUI, required: true },
    userData: { type: Object, required: true },
    isOnline: { type: Boolean, required: true },
    transform: { type: Object, required: true },
    sceneToggle: { type: Object, required: true },
    cache: { type: CacheManager, required: true },
    request: { type: RequestManager, required: true },
  },
  data() {
    return {
      left: 'left',
      transformMode: 'translate',
      addToggle: {
        camera: false,
        sunLight: false,
        areaLight: false,
        spotLight: false,
        pointLight: false,
      },
      matToggle: {
        button: 'material',
        light: false,
        material: false,
      },
    };
  },
  methods: {
    logoutClicked() {
      EventBus.emit('logoutUser');
    },
    renderClick() {
      const loggedIn = haveLoggedIn(this);
      if (!loggedIn) return EventBus.emit('setError', 'Please login first!');
      this.$emit('renderScene');
      // this.$emit('setSceneToggle', 'render', true);
      // this.scene.setSceneRender(false);
    },
    enableTransform() {
      return this.transform.enable && !this.sceneToggle.camera;
    },
    setActiveButton(button) {
      this.matToggle.button = button;
    },
    setTransformMode(mode) {
      this.transformMode = mode;
      this.$emit('setTransformMode', mode);
    },
    changePreview(lightMode) {
      this.matToggle.light = lightMode;
      this.matToggle.material = !lightMode;
      this.scene.changePreview(lightMode);
    },
    localTransformClicked() {
      const curValue = !this.sceneToggle.isLocal;
      this.$emit('setSceneToggle', 'isLocal', curValue);
      this.$emit('setLocalMode', curValue);
    },
    cameraModeClicked() {
      const isEnabled = this.sceneToggle.camera;
      if (!isEnabled) {
        if (!this.camPicked())
          return EventBus.emit('setError', 'Please select camera first!');
      }
      const curValue = !isEnabled;
      this.$emit('setSceneToggle', 'camera', curValue);
      this.$emit('setCamera', curValue);
    },
    camPicked() {
      const picked = this.scene.transform.getSelected();
      return picked && picked.name === 'REAL_CAM_GIZ';
    },
    modifyLight() {
      const picked = this.scene.transform.getSelected();
      if (!picked) return;
      if (!isRealLight(getMetaData(picked).type)) return;
      this.$emit('setSceneToggle', 'light', true);
    },
    cloneCamera() {
      const picked = this.scene.transform.getSelected();
      if (!picked || picked.name !== 'REAL_CAM_GIZ') return;
      const view = this.scene.camera;
      if (!view._isCamera)
        return EventBus.emit('setError', 'Camera not found!');
      const pickData = picked.metadata || {};
      const pickCam = pickData.camera;
      if (!pickCam) return EventBus.emit('setError', 'Camera not found!');
      REAL.Tool.CopyCameraTransform(view, pickCam);
      const pos = pickCam.position.clone();
      const quaternion = pickCam.rotationQuaternion.clone();
      picked.position.copyFrom(pos);
      picked.rotationQuaternion = quaternion;
      pickCam.position.set(0, 0, 0);
      pickCam.rotationQuaternion = undefined;
    },
    addClickToggle(key, value) {
      const keys = Object.keys(this.addToggle);
      for (const curKey of keys) {
        if (curKey === key) this.addToggle[curKey] = value;
        else this.addToggle[curKey] = false;
      }
    },
    activeBG(type, isHover = false) {
      return this.activeColor(this.matToggle.button === type, isHover);
    },
    activeTransformBG(type, isHover = false) {
      return this.activeColor(this.transformMode === type, isHover);
    },
    activeColor(condition, isHover = false) {
      if (condition) return isHover ? 'bg-blue-600' : 'bg-blue-800';
      return isHover ? 'bg-sc-dark2' : 'bg-sc-dark2-l';
    },
    addSceneItem(item, type, info = undefined) {
      this.$emit('addSceneItem', item, type, info);
    },
    exportScene() {
      this.$emit('setSceneToggle', 'export', true);
    },
  },
  beforeMount() {
    EventBus.on('setTransformMode', this.setTransformMode);
  },
  beforeUnmount() {
    EventBus.on('setTransformMode', this.setTransformMode);
  },
};
</script>
