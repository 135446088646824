<template>
  <div
    class="absolute top-0 left-0 w-full h-screen flex items-center justify-center"
  >
    <div class="w-full max-w-md mx-auto bg-gray-800 shadow-md rounded-lg p-6">
      <form class="space-y-4 md:space-y-6" @submit.prevent="handleSubmit">
        <slot></slot>
        <div class="flex items-center justify-between">
          <button
            type="submit"
            class="w-28 text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            {{ submitName }}
          </button>
          <button
            type="button"
            @click="$emit('close')"
            class="w-28 text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['submit', 'close'],
  props: {
    submitName: {
      type: String,
      required: false,
      default: () => {
        return 'Add';
      },
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('submit');
    },
  },
};
</script>
