import * as BABYLON from 'babylonjs';
import * as REAL from 'real_api_bbl';

export function getLightClass(lightType) {
  switch (lightType) {
    case 'REAL_SPOT_LIGHT':
      return REAL.SpotLight;
    case 'REAL_POINT_LIGHT':
      return REAL.PointLight;
    case 'REAL_SUN_LIGHT':
      return REAL.SunLight;
    case 'REAL_AREA_LIGHT':
      return REAL.AreaLight;
  }
}

export function getRealLight(light) {
  const metadata = getMetaData(light);
  const type = metadata.type;
  if (type === 'REAL_AREA_LIGHT') return light;
  const children = light.getChildren();
  const lightClass = getLightClass(type);
  for (const child of children) {
    if (child instanceof lightClass) return child;
  }
}

export function isRealLight(name) {
  if (typeof name !== 'string') return false;
  return name.startsWith('REAL_') && name.endsWith('_LIGHT');
}

export function isDirectionalLight(child) {
  return child instanceof BABYLON.DirectionalLight;
}

export function isAmbientLight(child) {
  return child instanceof BABYLON.HemisphericLight;
  // return child instanceof BABYLON.HemisphericLight || child instanceof BABYLON.PointLight;
}

export function isPointLight(child) {
  return child instanceof BABYLON.PointLight;
}

export function isSpotLight(child) {
  return child instanceof BABYLON.SpotLight;
}

export function getMetaData(child) {
  const metadata = child.metadata;
  return metadata ? child.metadata : {};
}

export function calculateBoundingBox(target) {
  const setSize = (mesh) => {
    if (mesh.getTotalVertices() <= 0) return;
    mesh.computeWorldMatrix(true);
    // let boundingBox = mesh.getBoundingInfo()?.boundingBox;
    if (mesh instanceof BABYLON.Mesh || mesh instanceof BABYLON.AbstractMesh) {
      let boundingBox = mesh.getBoundingInfo().boundingBox;
      let maximum = boundingBox.maximumWorld;
      let minimum = boundingBox.minimumWorld;
      min = new BABYLON.Vector3(
        Math.min(minimum.x, min.x),
        Math.min(minimum.y, min.y),
        Math.min(minimum.z, min.z)
      );
      max = new BABYLON.Vector3(
        Math.max(maximum.x, max.x),
        Math.max(maximum.y, max.y),
        Math.max(maximum.z, max.z)
      );
    }
  };
  let min = BABYLON.Vector3.One().scale(10000);
  let max = BABYLON.Vector3.One().scale(-10000);

  if (target instanceof BABYLON.AbstractMesh) {
    setSize(target);
  }

  let children = target.getChildMeshes();
  children.forEach((item) => {
    setSize(item);
  });
  // return max.subtract(min);
  return new BABYLON.BoundingBox(min, max);
}

export function worldQuaternionToEuler(object) {
  let quaternion = new BABYLON.Quaternion();
  object.getWorldMatrix().decompose(null, quaternion, null);
  let euler = quaternion.toEulerAngles();
  return new BABYLON.Vector3(
    parseFloat(euler.x.toFixed(6)),
    parseFloat(euler.y.toFixed(6)),
    parseFloat(euler.z.toFixed(6))
  );
}
