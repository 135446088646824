import * as BABYLON from 'babylonjs';
import { NameChildGiz } from '@/render_utils/three_tools/gizmos_tools/giz_tools';

export function createAreaGiz(light, bblScene) {
  const scale = light.scaling;
  const w = scale.x / 2;
  const h = scale.y / 2;
  const scene = bblScene.scene;
  const color = new BABYLON.Color3(0.96, 0.72, 0.34); // Equivalent to 0xf5b856
  const material = new BABYLON.StandardMaterial('gizMaterial', scene);
  material.emissiveColor = color;

  const points = [
    new BABYLON.Vector3(-w, -h, 0),
    new BABYLON.Vector3(w, -h, 0),
    new BABYLON.Vector3(w, h, 0),
    new BABYLON.Vector3(-w, h, 0),
    new BABYLON.Vector3(-w, -h, 0),
  ];
  const lines = BABYLON.MeshBuilder.CreateLines(
    'REAL_GIZ_LINES',
    { points: points },
    scene
  );
  lines.color = color;
  lines.parent = light;
  // lines.setParent(light);
  // lines.position.set(0, 0, 0)
  light.metadata = {
    canLight: true,
    gizID: light.uniqueId,
    type: 'REAL_AREA_LIGHT',
  };
  NameChildGiz(lines);
  bblScene.addIgnorable(lines);
}
