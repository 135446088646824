import * as BABYLON from 'babylonjs';
import EventBus from '@/utils/EventBus';
import { saveFileAs } from '@/render_utils/three_tools/export_tools/save_utils';
import R3DExporter from '@/render_utils/three_tools/export_tools/r3d_exporter';
import GltfExporter from '@/render_utils/three_tools/export_tools/gltf_exporter';
import {
  getLightClass,
  getMetaData,
  getRealLight,
  isRealLight,
} from '@/render_utils/three_tools/creation_tools/mesh_tools';

export default class RealExporter {
  constructor(scene, onProgress, showProgress, onLoading) {
    this.busy = false;
    this.scene = scene;
    this.warnings = [];
    this.exporter = {
      r3d: R3DExporter,
      glb: GltfExporter,
      gltf: GltfExporter,
    };
    this.sceneCache = {};
    this.onLoading = onLoading;
    this.onProgress = onProgress;
    this.showProgress = showProgress;
    this.sceneItems = this.__initScene();
  }

  async export(ext, options = {}, isRender = false) {
    if (this.busy)
      return EventBus.emit('setError', 'Already busy for previous task!');
    if (isRender && !this.scene.modelData.modelCamera)
      return EventBus.emit(
        'setError',
        'Please add a default camera to render!'
      );
    this.busy = true;
    if (ext === 'r3d') this.showProgress(true);
    else this.onLoading(true);
    this.__clear();
    const bblScene = this.scene;
    const scene = bblScene.scene;
    if (!scene) return EventBus.emit('setError', scene.error);
    let expScene = undefined;
    bblScene.transform.deselectPicked();
    bblScene.setSceneRender(false);
    bblScene.setControlRender(false);
    try {
      const reqList = [];
      const children = bblScene.children;
      for (const child of children) reqList.push(child);
      let isDone = await this.__prepareExport(ext, scene, reqList);
      if (!isDone) return this.__finish();
      expScene = await this.__export_to(scene, ext, options);
      if (!expScene) return this.__finish();
      // await saveFileAs(expScene, ext);
      if (!isRender) await saveFileAs(expScene, ext);
      // saveFileAs;
    } catch (e) {
      EventBus.emit('setError', e);
    }
    this.__finish();
    return expScene;
  }

  async __prepareExport(ext, scene, children) {
    try {
      const sceneItems = this.sceneItems;
      const { realLights, cameras } = await this.__traverseChildren(children);
      // const {realLights} = await this.__traverseChildren(children);
      const keys = Object.keys(sceneItems);
      for (const key of keys) {
        const reqChildren = sceneItems[key];
        if (!reqChildren || !reqChildren.length) continue;
        this.__storeCache(reqChildren);
      }
      this.__addIgnoreList(cameras);
      this.__addIgnoreList(realLights);
      if (ext === 'r3d') {
        const parsed = await this.__parseLights(scene, realLights);
        sceneItems.lights.push(...parsed);
      }
      const parsed = await this.__parseCameras(scene, cameras);
      sceneItems.cameras.push(...parsed);
      for (const model of sceneItems.models) this.__detach(model);
      return true;
    } catch (e) {
      console.log(e);
      EventBus.emit('setError', e);
    }
  }

  async __traverseChildren(children) {
    const that = this;
    const data = { cameras: [], realLights: [] };
    const sceneItems = this.sceneItems;
    // const addIgnore = this.__addIgnore;
    return new Promise((resolve) => {
      const delay = 20;
      let curIndex = 0;

      function traverseChildren(curIndex, children, that) {
        if (curIndex >= children.length) {
          resolve(data);
          return;
        }
        const child = children[curIndex++];
        const metadata = getMetaData(child);
        const type = metadata.type;
        if (isRealLight(type)) data.realLights.push(getRealLight(child));
        else if (type === 'REAL_CAM_GIZ')
          data.cameras.push(child.metadata.camera);
        else if (child.name === 'REAL_EMPTY_GIZ') {
          that.__addIgnore(child);
          const children = child.getChildren();
          if (!children || !children.length)
            return traverseChildren(curIndex, children, that);
          const root = children[0];
          that.__addIgnore(root);
          const meshes = root.getChildren();
          if (!meshes || !meshes.length)
            return traverseChildren(curIndex, children, that);
          for (const mesh of meshes) sceneItems.models.push(mesh);
        }
        // else {
        //   console.error("NONE", child.name, type, child);
        // }
        setTimeout(() => {
          traverseChildren(curIndex, children, that);
        }, delay);
      }

      traverseChildren(curIndex, children, that);
    });
  }

  async __parseCameras(scene, cameras) {
    const parsed = [];
    return new Promise((resolve) => {
      const delay = 20;
      let curIndex = 0;

      function processCamera(curIndex, scene, cameras) {
        if (curIndex >= cameras.length) {
          resolve(parsed);
          return;
        }

        function copyTransform(camera, eye) {
          let position = new BABYLON.Vector3();
          let quaternion = new BABYLON.Quaternion();
          eye.getWorldMatrix().decompose(null, quaternion, position);

          function copyRotationFromAbsolute(sourceCamera, targetCamera) {
            const absoluteRotationQuaternion =
              sourceCamera.absoluteRotation.clone();
            const eulerRotation = new BABYLON.Vector3();
            absoluteRotationQuaternion.toEulerAnglesToRef(eulerRotation);
            const quaternion = new BABYLON.Quaternion();
            BABYLON.Quaternion.FromEulerAnglesToRef(
              eulerRotation.x,
              eulerRotation.y,
              eulerRotation.z,
              quaternion
            );
            targetCamera.rotationQuaternion = quaternion;
          }

          camera.position.copyFrom(position);
          if (eye.absoluteRotation) copyRotationFromAbsolute(eye, camera);
          else camera.rotationQuaternion = quaternion;
        }

        const camera = cameras[curIndex++];
        // const clone = REAL.CameraToEye(camera, scene);
        const clone = camera.clone();
        const children = camera.getChildren();
        if (children) {
          for (const child of children) {
            child.dispose();
          }
        }
        clone.parent = undefined;
        clone.name = `${camera.name}_PARSED`;
        clone.rotationQuaternion = undefined;
        copyTransform(clone, camera);
        parsed.push(clone);
        setTimeout(() => {
          processCamera(curIndex, scene, cameras);
        }, delay);
      }

      processCamera(curIndex, scene, cameras);
    });
  }

  async __parseLights(scene, lights) {
    const parsed = [];
    const that = this;
    return new Promise((resolve) => {
      const delay = 20;
      let curIndex = 0;

      function processLight(curIndex, scene, lights, that) {
        if (curIndex >= lights.length) {
          resolve(parsed);
          return;
        }
        const light = lights[curIndex++];
        const metadata = getMetaData(light);
        const lightClass = getLightClass(metadata.type);
        if (!lightClass) {
          that.warnings.push(
            `Light ${light.name} is missing data so can't be included`
          );
          return processLight(curIndex, scene, lights, that);
        }
        const newLight = light.deepCopy();
        newLight.parent = null;
        parsed.push(newLight);
        setTimeout(() => {
          processLight(curIndex, scene, lights, that);
        }, delay);
      }

      processLight(curIndex, scene, lights, that);
    });
  }

  __addIgnoreList(ignoreList) {
    for (const ignore of ignoreList) this.__addIgnore(ignore);
  }

  __addIgnore(ignore) {
    if (!this.sceneItems.ignore.includes(ignore))
      this.sceneItems.ignore.push(ignore);
  }

  __resetExtra() {
    const extras = this.sceneItems.extras;
    const scene = this.scene.scene;
    for (const child of extras) {
      scene.removeMesh(child);
    }
  }

  expError(error) {
    // let errorMsg = "";
    // for (const error of errors) errorMsg += `${error}\n`;
    // EventBus.emit("setError", errorMsg);
    EventBus.emit('setError', String(error));
    // console.log("ERROR", error)
  }

  __finish() {
    this.__restoreCache();
    const threeScene = this.scene;
    for (const light of [...this.sceneItems.lights]) this.__dispose(light);
    for (const camera of [...this.sceneItems.cameras]) this.__dispose(camera);
    this.sceneCache = {};
    threeScene.setSceneRender(true);
    threeScene.setControlRender(true);
    this.busy = false;
    this.onLoading(false);
    this.showProgress(false);
    // for (const rootNode of threeScene.scene.rootNodes) {
    //   console.log("rootNode", rootNode.name, rootNode.constructor.name);
    // }
  }

  __dispose(item) {
    if (!item) return;
    item.dispose();
  }

  async __export_to(scene, ext, options) {
    const exporter = this.exporter[ext];
    if (!exporter) return EventBus.emit('setError', 'File type not supported!');
    const expClass = new exporter();
    if (['glb'].includes(ext)) options.binary = true;
    this.__addIgnoreList(this.scene.modelData.ignoreList);
    const ignoreList = this.sceneItems.ignore;
    if (ext === 'r3d')
      return await expClass.export(
        scene,
        this.onProgress,
        this.expError,
        ignoreList
      );
    // return await expClass.exportTest(scene, options, ignoreList);
    return await expClass.export(scene, options, ignoreList);
  }

  __storeCache(children) {
    const cache = this.sceneCache;
    for (const child of children) {
      const id = this.__cacheSize();
      cache[id] = { child: child, parent: child.parent };
    }
  }

  __restoreCache() {
    const cache = this.sceneCache;
    const entries = Object.keys(cache);
    for (const entry of entries) {
      const item = cache[entry];
      const child = item.child;
      const parent = item.parent;
      if (parent) {
        if (child._isDirty) child.parent = parent;
        else child.setParent(parent);
      }
    }
  }

  __cacheSize() {
    const cache = this.sceneCache;
    const entries = Object.keys(cache);
    return entries.length;
  }

  __initScene() {
    return {
      ignore: [],
      extras: [],
      models: [],
      cameras: [],
      lights: [],
    };
  }

  __detach(child, isDirty = false) {
    if (child._isDirty || isDirty) child.parent = null;
    else child.setParent(null);
  }

  __clear() {
    this.warnings = [];
    this.sceneCache = {};
    this.sceneItems = this.__initScene();
  }
}
