import * as BABYLON from 'babylonjs';
import { NameGiz } from '@/render_utils/three_tools/gizmos_tools/giz_tools';
import { DynamicLine } from '@/render_utils/three_tools/creation_tools/DynamicLine';

export async function createSpotGiz(light, bblScene) {
  const scene = bblScene.scene;
  const color = new BABYLON.Color3(1, 1, 1);
  const giz = bblScene.giz;
  const icon = await giz.load('spot');

  const target = light.target;

  const hit = new BABYLON.TransformNode('REAL_EXTRA', scene);
  hit.setParent(light);
  hit.name = 'REAL_EXTRA';
  const line = DynamicLine(hit, target, color, scene);
  line.setParent(icon);

  light.name = 'REAL_SPOT_LIGHT';
  light.parent = icon;

  NameGiz(icon);

  icon.metadata = {
    canLight: true,
    ignoreScale: true,
    childID: light.uniqueId,
    type: 'REAL_SPOT_LIGHT',
    depends: [line, target, hit],
  };
  light.metadata = { type: 'REAL_SPOT_LIGHT' };

  bblScene.addIgnorable(hit);
  bblScene.addIgnorable(line);
  bblScene.addIgnorable(icon);

  return icon;
}
