<template>
  <LoginPage
    v-if="showLogin"
    :icons="icons"
    @loginClicked="loginClicked"
    @close="showLogin = false"
  />
  <div
    v-if="isLoggedIn()"
    @mouseenter="dropdown = true"
    @mouseleave="dropdown = false"
  >
    <div
      class="flex items-center cursor-pointer border border-gray-600 rounded-full p-1"
    >
      <span
        v-if="isOnline"
        class="size-3.5 bg-green-500 border-2 border-gray-800 rounded-full"
      ></span>
      <span
        v-else
        class="size-3.5 bg-red-500 border-2 border-gray-800 rounded-full"
      ></span>
      <div
        class="size-6 flex items-center justify-center bg-gradient-to-br from-gray-700 to-gray-400 rounded-full text-white"
      >
        {{ getAvatar() }}
      </div>
    </div>

    <!-- Dropdown menu -->
    <div
      id="userDropdown"
      v-if="dropdown"
      class="absolute z-10 divide-y rounded-lg shadow w-44 bg-gray-700 divide-gray-600"
      :style="{ right: '0', left: 'auto' }"
    >
      <div class="px-4 py-3 text-sm text-white">
        <div>{{ getName() }}</div>
        <div class="font-medium truncate">{{ getEmail() }}</div>
      </div>
      <ul class="py-2 text-sm text-gray-200" aria-labelledby="avatarButton">
        <li>
          <a
            :href="getDash()"
            class="block px-4 py-2 hover:bg-gray-600 hover:text-white"
            >Dashboard</a
          >
        </li>
      </ul>
      <div class="py-1">
        <a
          href="#"
          @click="logoutClicked"
          class="block px-4 py-2 text-sm hover:bg-gray-600 text-gray-200 hover:text-white"
          >Sign out</a
        >
      </div>
    </div>
  </div>
  <div v-else>
    <MenuButton title="Me" :img="icons.usrIcon" @click="loginClicked(true)" />
  </div>
</template>
<script>
import { IconsUI } from '@/utils/IconsUI';
import LoginPage from '@/pages/LoginPage.vue';
import CacheManager from '@/utils/CacheManager';
import { userLoggedIn } from '@/tools/login_tools';
import MenuButton from '@/components/ui/MenuButton.vue';

export default {
  name: 'UserProfile',
  emits: ['logoutClicked'],
  components: { LoginPage, MenuButton },
  props: {
    icons: { type: IconsUI, required: true },
    userData: { type: Object, required: true },
    isOnline: { type: Boolean, required: true },
    cache: { type: CacheManager, required: true },
  },
  data() {
    return {
      dropdown: false,
      showLogin: false,
    };
  },
  methods: {
    getAvatar() {
      const name = this.getName();
      const avatar = name.charAt(0);
      return avatar.toUpperCase();
    },
    getDash() {
      const dom = 'https://realistic3.com';
      const loginCache = this.cache.loginCache();
      const data = loginCache.data;
      const token = loginCache.token;
      if (!data || !token) return '#';
      return `${dom}?data=${data}&&token=${token}`;
    },
    getEmail() {
      return this.userData.user.email;
    },
    getName() {
      return this.userData.user.userName;
    },
    loginClicked(show) {
      this.showLogin = show;
    },
    logoutClicked() {
      this.$emit('logoutClicked');
    },
    isLoggedIn() {
      return userLoggedIn(this.userData);
    },
  },
};
</script>
