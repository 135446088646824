import CryptoJS from 'crypto-js';

export default class CryptoManager {
  constructor() {}
  generateKey() {
    const enc = CryptoJS.enc;
    const lib = CryptoJS.lib;
    const randomWords = lib.WordArray.random(16);
    return enc.Hex.stringify(randomWords);
  }
  encrypt(data, encryptionKey) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
  }
  decrypt(encryptedData, encryptionKey) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
}
