export async function LoginUser(app, data, password, isCred = false) {
  const response = await app.request.login(data, password, isCred);
  const msg = response.msg;
  if (msg !== 'SUCCESS') return showLoginError(app, msg);
  const userData = response.data;
  app.userData = userData && userData.user ? userData : {};
  await app.setCred();
  if (userLoggedIn(userData))
    app.cache.setCache('login', { data: data, token: userData.token });
}

function showLoginError(app, msg) {
  switch (msg) {
    case 'AUTH_FAILED':
      app.setError('Wrong account or password!');
      break;
    case 'NOT_FOUND':
      app.setError('User not found!');
      break;
    default:
      console.error(msg);
      app.setError('User not found!');
      break;
  }
}

export function haveLoggedIn(gui) {
  const loggedIn = userLoggedIn(gui.userData);
  if (loggedIn) return true;
  gui.resVisible = false;
  gui.loginVisible = true;
  gui.renderVisible = false;
  gui.addCameraVisible = false;
  gui.addSunLightVisible = false;
  gui.addAreaLightVisible = false;
  return false;
}

export function userLoggedIn(userData) {
  return !!userData && !!userData.user;
}
