import * as REAL from 'real_api_bbl';
import EventBus from '@/utils/EventBus';
import { GLTF2Export } from 'babylonjs-serializers';

export default class GltfExporter {
  constructor() {
    this.exporter = new REAL.GltfExporter();
  }

  async export(scene, options = {}, ignoreList = []) {
    const exporter = this.exporter;
    const blob = await exporter.export(scene, options, ignoreList);
    if (exporter.error) return EventBus.emit('setError', exporter.error);
    return blob;
  }

  async exportTest(scene, options = {}, ignoreList = []) {
    const removeNoopRootNodes = options.removeNoopRootNodes || true;
    const ignoreSet = new Set(ignoreList);
    let expOptions = {
      shouldExportNode: (node) => {
        return !ignoreSet.has(node);
      },
      removeNoopRootNodes: removeNoopRootNodes,
    };
    const buffers = await GLTF2Export.GLTFAsync(scene, 'Real3D', expOptions);
    const files = buffers.glTFFiles;
    // const fileNames = Object.keys(files);

    const gltfFile = files['Real3D.gltf'];
    const binFile = files['Real3D.bin'];

    async function blobToBase64(blob) {
      const arrayBuffer = await blob.arrayBuffer();
      const bytes = new Uint8Array(arrayBuffer);
      let binary = '';
      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return btoa(binary);
    }

    const gltfJson = JSON.parse(gltfFile);
    const base64data = await blobToBase64(binFile);
    gltfJson.buffers[0].uri = `data:application/octet-stream;base64,${base64data}`;

    const images = Object.keys(files).filter(
      (key) => key !== 'Real3D.gltf' && key !== 'Real3D.bin'
    );
    for (const image of images) {
      const imageData = files[image];
      const imageIndex = gltfJson.images.findIndex((img) => img.uri === image);
      if (imageIndex !== -1) {
        gltfJson.images[imageIndex].uri =
          'data:image/png;base64,' + (await blobToBase64(imageData));
      }
    }

    return new Blob([JSON.stringify(gltfJson, null, 2)], {
      type: 'application/json',
    });
  }
}
