import EventBus from '@/utils/EventBus';
import GltfImporter from '@/render_utils/three_tools/import_tools/gltf_importer';

export default class GizManager {
  constructor(scene, cache) {
    this._cache = cache;
    this._scene = scene;
    this._iconCache = {};
    // cache.clearAll()
    // console.log(cache.all())
    console.log(`Total cache size ${cache.size()}MB`);
  }
  async load(name) {
    try {
      const parent = await this._getIcon(name);
      if (!parent) return;
      const icon = parent.getChildren()[0];
      icon.setParent(null);
      parent.dispose();
      icon.material.unlit = true;
      icon.position.set(0, 0, 0);
      icon.rotationQuaternion = undefined;
      icon.scaling.set(1, 1, 1);
      return icon;
    } catch (e) {
      console.error(e);
      this._setError(e.toString());
    }
  }
  async _getIcon(name) {
    const fileName = this._getName(name);
    if (!fileName) return this._setError('Icon not found');

    if (this._hasCached(fileName)) {
      let blob = this._iconCache[fileName];
      return await this._loadIcon(blob, true);
    }

    if (!this._cache.contains(fileName)) {
      const arrayBuffer = await this._readGLBFile(fileName);
      if (!arrayBuffer) return;
      this._cache.setCache(fileName, arrayBuffer, true);
    }

    const blob = this._cache.getCache(fileName, true);
    if (!blob) return this._setError('Icon loading failed');

    return await this._loadIcon(blob, true);
  }
  async _loadIcon(file, isBlob = false) {
    if (isBlob) {
      const blobURL = URL.createObjectURL(file);
      const importer = new GltfImporter(this._scene.scene, blobURL);
      // return await importer.importFromBuffer(true, true);
      return await importer.importFromBuffer(true, true);
    }
    return await this._loadModelFromPath(file);
  }
  _hasCached(fileName) {
    if (!fileName) return false;
    return fileName in this._iconCache;
  }
  _setError(msg) {
    msg = msg.toString();
    return EventBus.emit('setError', msg);
  }
  async _loadModelFromPath(fileName) {
    const path = this._getPath(fileName);
    const importer = new GltfImporter(this._scene.scene, path);
    return await importer.import();
  }
  async _readGLBFile(fileName) {
    try {
      const path = this._getPath(fileName);
      const response = await fetch(path);
      if (!response.ok) {
        const msg = `Failed to fetch the file: ${response.statusText}`;
        console.error(msg);
        return this._setError(msg);
      }
      return await response.arrayBuffer();
    } catch (error) {
      const msg = `Failed to fetch the file: ${error}`;
      console.error(msg);
      this._setError(msg);
    }
  }
  _getPath(fileName) {
    return `./models/giz/${fileName}`;
  }
  _getName(name) {
    if (!name) return;
    if (name.toLocaleLowerCase().endsWith('.glb')) return name;
    return `${name}.glb`;
  }
}
