import * as BABYLON from 'babylonjs';
import { DynamicLineFrame } from '@/render_utils/three_tools/creation_tools/DynamicLine';

export async function createSunGiz(sun, bblScene) {
  const giz = bblScene.giz;
  const radius = 0.05;
  const scene = bblScene.scene;
  const color = new BABYLON.Color3(1, 1, 1);
  const targetMaterial = new BABYLON.StandardMaterial('REAL_GIZ_MAT', scene);
  const icon = await giz.load('sun');
  // await bblScene.svg.load("camera");
  // await giz.load("camera")
  // const mat = icon.material;

  // const axes = new BABYLON.Debug.AxesViewer(scene);
  // axes.xAxis.parent = icon;
  // axes.yAxis.parent = icon;
  // axes.zAxis.parent = icon;

  const target = BABYLON.MeshBuilder.CreateSphere(
    'REAL_CHILD_GIZ',
    { diameter: radius, segments: 32 },
    scene
  );
  target.material = targetMaterial;
  target.visibility = 0.0;

  icon.position = sun.position;
  target.position = sun.target;

  const line = DynamicLineFrame(icon, target, color, scene);
  // line.material = mat;
  // line.setParent(icon);
  sun.parent = icon;
  // target.parent = icon;
  // sun.position = BABYLON.Vector3.Zero();

  icon.metadata = {
    canLight: true,
    ignoreScale: true,
    ignoreRotate: true,
    childID: sun.uniqueId,
    type: 'REAL_SUN_LIGHT',
    depends: [line, target],
  };

  sun.metadata = { type: 'REAL_SUN_LIGHT' };

  bblScene.addIgnorable(target);
  bblScene.addIgnorable(line);
  bblScene.addIgnorable(icon);

  const sunPos = sun.getAbsolutePosition();
  const targetPos = target.getAbsolutePosition();
  let curSunPos = new BABYLON.Vector3();
  let curTargetPos = new BABYLON.Vector3();

  const observeTarget = () => {
    if (curSunPos.equals(sunPos) && curTargetPos.equals(targetPos)) return;
    curSunPos = new BABYLON.Vector3().copyFrom(sunPos);
    curTargetPos = new BABYLON.Vector3().copyFrom(targetPos);
    sun.position = curSunPos;
    sun.target = curTargetPos;
  };

  scene.registerAfterRender(observeTarget);

  return { icon: icon, target: target };
}
