export function RadToDeg(radians) {
  const value = parseFloat(radians);
  const degrees = value * (180 / Math.PI);
  return roundValue(degrees);
}

export function DegToRad(degrees) {
  const value = parseFloat(degrees);
  return value * (Math.PI / 180);
}

export function roundValue(value, fact = 6) {
  let trimmedValue = parseFloat(value.toString());
  let formattedValue = trimmedValue.toPrecision(fact);
  let parsedValue = parseFloat(formattedValue);
  const threshold = Math.pow(10, -fact / 2);
  if (Math.abs(parsedValue) < threshold) {
    return 0;
  }
  return parsedValue;
}
