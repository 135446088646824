<template>
  <div
    v-if="isVisible"
    id="popup-modal"
    class="fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center z-50 backdrop-filter backdrop-blur-md"
  >
    <div class="relative p-4 w-full max-w-md max-h-full">
      <div class="relative rounded-lg shadow bg-gray-700">
        <div class="p-4 md:p-5 text-center">
          <svg
            class="mx-auto mb-4 size-8 text-gray-200"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          <h3 class="mb-5 text-lg font-normal text-gray-300">
            {{ errorMessage }}
          </h3>
          <button
            @click="onlineClick"
            data-modal-hide="popup-modal"
            type="button"
            class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
          >
            Re-connect
          </button>
          <button
            @click="clearError"
            data-modal-hide="popup-modal"
            type="button"
            class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorOnlineView',
  emits: ['clearError', 'onlineClick'],
  props: {
    errorMessage: {
      type: [String, null],
      required: true,
    },
  },
  data() {
    return {
      isVisible: !!this.errorMessage,
    };
  },
  watch: {
    errorMessage(newValue) {
      this.isVisible = !!newValue;
    },
  },
  methods: {
    onlineClick() {
      this.$emit('onlineClick');
      this.$emit('clearError');
    },
    clearError() {
      this.$emit('clearError');
    },
  },
};
</script>
