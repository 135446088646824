<template>
  <div
    class="absolute top-0 left-0 w-full flex items-center justify-center backdrop-blur-md"
  >
    <section
      class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
    >
      <div
        class="w-full bg-gray-800 rounded-lg border border-gray-700 shadow md:mt-0 sm:max-w-md xl:p-0"
      >
        <a
          class="flex items-center justify-center mb-6 mt-6 text-2xl font-semibold text-white"
        >
          <img class="size-7 mr-2" :src="icons.r3dIcon" alt="logo" />
          Realistic 3D
        </a>
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <form class="space-y-4 md:space-y-6" @submit.prevent="loginClicked">
            <div>
              <label
                for="username"
                class="block mb-2 text-sm font-medium text-white"
                >Username or Email</label
              >
              <input
                type="text"
                id="username"
                v-model="data"
                name="username"
                class="bg-gray-700 border border-gray-600 text-white sm:text-sm rounded-lg placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@email.com"
                required=""
              />
            </div>
            <div>
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-white"
                >Password</label
              >
              <input
                type="password"
                name="password"
                id="password"
                v-model="password"
                placeholder="••••••••"
                class="bg-gray-700 border border-gray-600 text-white sm:text-sm rounded-lg placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
              />
            </div>

            <div class="flex items-center justify-between">
              <a
                :href="goToForgotPassword()"
                class="text-sm font-medium text-white hover:underline"
                target="_blank"
                >Forgot password?</a
              >
            </div>

            <div class="flex items-center justify-between">
              <button
                type="submit"
                class="w-28 text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Sign in
              </button>
              <button
                type="button"
                @click="$emit('close')"
                class="w-28 ml-2 text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Close
              </button>
            </div>

            <p class="text-sm font-light text-white">
              Don’t have an account yet?
              <a
                :href="goToSignup()"
                class="font-medium text-primary-500 hover:underline"
                target="_blank"
                >Sign up</a
              >
            </p>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { IconsUI } from '@/utils/IconsUI';
import EventBus from '@/utils/EventBus';

export default {
  name: 'LoginPage',
  emits: ['loginClicked', 'close'],
  props: {
    icons: { type: IconsUI, required: true },
  },
  data() {
    return {
      data: '',
      password: '',
      remember: false,
    };
  },
  methods: {
    loginClicked() {
      console.log('Logging in...');
      EventBus.emit('loginClicked', {
        data: this.data,
        password: this.password,
      });
      this.$emit('loginClicked', false);
    },
    goToSignup() {
      return `https://realistic3.com/#/login`;
    },
    goToForgotPassword() {
      return `https://realistic3.com/#/login`;
    },
  },
};
</script>
