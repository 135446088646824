import * as REAL from 'real_api_bbl';
import EventBus from '@/utils/EventBus';

export default class SocketManager {
  constructor(onMessage) {
    this.cred = {};
    this.socket = null;
    this.connected = false;
    this.connecting = false;
    this.isLoggedIn = false;
    this.__url = null;
    this.__connectCount = 0;
    this.__domain = REAL.Domain;
    this.__onMessage = onMessage;
  }
  async login(cred) {
    if (this.connected || this.connecting || !cred) return;
    this.cred = cred;
    await this.__connect();
  }
  async __connect() {
    this.__url = this.getWUrl();
    this.connecting = true;
    this.socket = new WebSocket(this.__url);
    this.initSocket();
  }
  offline(reason) {
    this.isLoggedIn = false;
    this.socketClosed();
    EventBus.emit('setOnlineError', reason);
  }
  initSocket() {
    this.socket.onopen = this.onOpen.bind(this);
    this.socket.onerror = this.socketError.bind(this);
    this.socket.onmessage = this.onMessages.bind(this);
    this.socket.onclose = this.socketClosed.bind(this);
  }
  onOpen() {
    this.connected = true;
    this.connecting = false;
    this.__connectCount = 0;
    EventBus.emit('userStatus', 1);
  }
  onMessages(messageEvent) {
    try {
      const message = messageEvent.data;
      if (!message) return;
      if (!message.startsWith('{')) return;
      const renderInfo = JSON.parse(message);
      const msg = renderInfo.msg;
      const data = renderInfo.data;
      const type = renderInfo.type;
      this.__onMessage(type, msg, data);
    } catch (e) {
      console.error('ERROR onMessages', e);
    }
  }
  socketClosed() {
    this.connecting = false;
    this.connected = false;
    EventBus.emit('userStatus', 0);
    if (!this.isLoggedIn) return;
    console.log('Socket has been closed!');
    this.socketReconnect();
  }
  socketReconnect() {
    if (this.__connectCount > 3) return;
    console.log('Reconnecting');
    this.__connectCount++;
    this.socket = new WebSocket(this.__url);
    this.initSocket();
  }
  socketError(evt) {
    this.connecting = false;
    EventBus.emit('userStatus', 0);
    console.error('There was an error in connecting socket!');
    console.log('EVENT', evt);
  }
  getWUrl() {
    const cred = this.cred;
    const insID = cred.insID;
    const appKey = cred.appKey;
    const prodKey = cred.prodKey;
    return `wss://${this.__domain}/realAPI?ins_id=${insID}&prod_key=${prodKey}&app_key=${appKey}&exp_from=app`;
  }
  isOnline() {
    return this.connected && this.isLoggedIn;
  }
}
