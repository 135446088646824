import * as BABYLON from 'babylonjs';
import EventBus from '@/utils/EventBus';

export default class SvgMesh {
  constructor(scene, cache) {
    this._cache = cache;
    this._scene = scene;
    this._iconCache = {};
    cache.remove('camera.svg');
  }
  load(name, lookCam = false) {
    try {
      const mesh = this._getIcon(name);
      if (!mesh) return;
      if (lookCam) mesh.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
      // console.log(mesh);
      return mesh;
    } catch (e) {
      console.error(e);
      this._setError(e.toString());
    }
  }
  _getIcon(name, size = 0.5) {
    const scene = this._scene.scene;
    const fileName = this._getName(name);
    const path = this._getPath(fileName);
    const plane = BABYLON.MeshBuilder.CreatePlane(name, { size: size }, scene);

    const material = new BABYLON.PBRMaterial(`${name}_MATERIAL`, scene);
    const texture = new BABYLON.Texture(
      path,
      scene,
      true,
      false,
      BABYLON.Texture.TRILINEAR_SAMPLINGMODE,
      () => {
        texture.uScale = 1;
        texture.vScale = -1;
        material.albedoTexture = texture;
        material.albedoTexture.hasAlpha = true;
        material.backFaceCulling = false; // Show both sides of the plane
        material.alphaMode = BABYLON.Engine.ALPHA_COMBINE;
        material.unlit = true; // Ensure the material is not affected by lighting
        plane.material = material;
      }
    );

    return plane;
  }

  _getPath(fileName) {
    return `./models/svg/${fileName}`;
  }
  _getName(name) {
    if (!name) return;
    if (name.toLocaleLowerCase().endsWith('.svg')) return name;
    return `${name}.svg`;
  }
  _setError(msg) {
    msg = msg.toString();
    return EventBus.emit('setError', msg);
  }
}
