<template>
  <div
    class="fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center z-50 backdrop-filter backdrop-blur-md"
  >
    <div class="w-64 bg-gray-200 rounded-full dark:bg-gray-700">
      <div
        class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-1 leading-none rounded-full"
        :style="{ width: progress + '%' }"
      >
        {{ progress }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressView',
  props: {
    progress: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100,
    },
  },
};
</script>
